.form__print__list {
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-size: 16px;
}
.form__print__list .anticon-file-excel {
  color: #007500;
}
.form__print__list .anticon-file-pdf {
  color: #7f0000;
}
.form__print__list .anticon-printer {
  color: #0047ab;
}
.form__print__list .anticon-file-word {
  color: #0096ff;
}
