body {
    font-size: 16px !important;
}
.registrationBody {
    max-width: 1024px;
    background: #ffffff;
    background-image: url("./reg_bg2.jpg");
    background-repeat: no-repeat;
    margin: auto;
    border-radius: 10px;
    padding: 30px;
    padding-bottom: 200px !important;
}

.registrationMainBody {
    height: 100%;
    padding: 100px;
}

.contactTable tr, th, td {
    border: 1px solid #164d9d
}

.contactTable {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    background-color: white;
}
.contactTable th, td {
    padding: 8px 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
.contactTable th {
    background-color: #164d9d;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.03em;
}
.contactTable tr:hover {
    background-color: #f1f1f1;
}
.contactTable tr:nth-child(even) {
    background-color: #f9f9f9;
}
.contactTable td {
    color: #333;
}

/* Mobile styles */
@media (max-width: 767px) {
    .registrationBody {
        background: #ffffff;
        background-image: url("./reg_bg2.jpg");
        background-repeat: no-repeat;
        margin: auto;
        border-radius: 10px;
        padding: 30px;
        padding-bottom: 200px !important;
    }

    .registrationMainBody {
        height: 100%;
        padding: 20px;
        padding-bottom: 30px
    }
}