.submit__btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.submit__btnmodal {
  margin-top: 0;
}
.submit__btn.modal {
  margin-top: 0!important;
}
@media screen and (max-width: 450px) {
  .submit__btn {
    flex-direction: column;
  }
  .submit__btn button {
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
}
